import styled from 'styled-components';

import { fontSize, media, spacing, toRem } from '@styles/Theme';
import { H5, P } from '@styles/Typography';

import {
	Section as LSection,
	Wrapper as LWrapper,
} from '@components/Layout/styles';

import bgItemA from '@images/bg-differentials-a.svg';
import bgItemB from '@images/bg-differentials-b.svg';

export const Section = styled(LSection)`
	background-color: #fafafa;
	background-image: linear-gradient(#fafafa, #f9f9f9);
`;

export const Wrapper = styled(LWrapper)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	iframe {
		width: 70%;
		height: 50vh;
		border: none;
		margin: ${spacing(3)}
	}

	@media (${media.mobileOnly}) {
		iframe {
			width: 100%;
			min-height: 300px;
			height: 100%;
		}
	}
`;

export const DifferentialContainer = styled.div`
	display: flex;
	margin-top: ${spacing(5)};

	@media (${media.mobileOnly}) {
		margin-top: ${spacing(7)};
		overflow-x: auto;
	}

	@media (${media.tablet}) {
		justify-content: space-between;
		margin-top: ${spacing(10)};
	}
`;

export const DifferentialIcon = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${toRem(144)};
	height: ${toRem(120)};
	margin-bottom: ${spacing(3)};

	&::before {
		position: absolute;
		width: 100%;
		height: 100%;
		background: url(${bgItemA}) no-repeat;
		opacity: 0.3;
		content: '';
	}
`;

export const DifferentialItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	& + & {
		margin-left: ${spacing(1)};

		@media (${media.tablet}) {
			margin-left: ${spacing(2)};
		}

		@media (${media.desktop}) {
			margin-left: ${spacing(7)};
		}
	}

	&:nth-child(odd) {
		${DifferentialIcon} {
			&::before {
				background: url(${bgItemB});
			}
		}
	}

	@media (${media.mobileOnly}) {
		min-width: ${toRem(288)};
		padding: ${spacing(4)};
		background-color: #fff;
		border-radius: ${spacing(2)};
	}

	@media (${media.tablet}) {
		width: calc(100% / 4);
	}
`;

export const Icon = styled.img`
	display: block;
	width: ${toRem(48)};
`;

export const DifferentialTitle = styled(H5)``;

export const DifferentialDescription = styled(P)`
	font-size: ${fontSize.small};
`;
