import React from 'react';
import PropTypes from 'prop-types';

import { H2 } from '@styles/Typography';

import { ShadowCard } from '@components/Cards';

import * as S from './styles';

const Item = ({ title, content, label, link, img }) => {
	return (
		<>
			<S.Item>
				<ShadowCard img={img} title={title} content={content} type="image" />

				<S.TextContainer>
					{title && <H2>{title}</H2>}
					{content && <S.Content>{content}</S.Content>}
					{link && <S.CTA label={label} to={link} withIcon />}
				</S.TextContainer>
			</S.Item>
		</>
	);
};

Item.propTypes = {
	content: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default Item;
