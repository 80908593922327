import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TitleSection from '@components/TitleSection';
import AppointmentButton from '@components/Button/AppointmentButton';
import CardList from '@components/CardList';

import { orderAndHideItems } from '@utils/filter';

import * as S from './styles';

function Differentials() {
	const { allCosmicjsHomepage } = useStaticQuery(graphql`
		{
			allCosmicjsHomepage(filter: { slug: { eq: "differentials" } }) {
				nodes {
					metadata {
						differentials {
							description
							icon {
								imgix_url
							}
							order
							title
							show
						}
						hat
						title
						description
					}
				}
			}
		}
	`);

	const {
		description,
		hat,
		title,
		differentials,
	} = allCosmicjsHomepage.nodes[0].metadata;

	const filteredDifferentials = orderAndHideItems(differentials);

	return (
		<S.Section>
			<S.Wrapper>
				<iframe
					auto="true"
					src="https://www.youtube.com/embed/71YJEkWAUwI"
					title="Fleury Infusões"
					allowFullScreen
				/>
				<TitleSection hat={hat} title={title} content={description}>
					<AppointmentButton />
				</TitleSection>
				<CardList list={filteredDifferentials} />
			</S.Wrapper>
		</S.Section>
	);
}

export default Differentials;
