import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Item from './Item';

import * as S from './styles';

const MobileService = () => {
	const { allCosmicjsHomepage } = useStaticQuery(graphql`
		{
			allCosmicjsHomepage(filter: { slug: { eq: "mobile-services" } }) {
				nodes {
					metadata {
						services {
							title
							content
							cta_label
							cta_link
							image {
								imgix_url
							}
						}
					}
				}
			}
		}
	`);

	const { services } = allCosmicjsHomepage.nodes[0].metadata;

	return (
		<>
			<S.Section>
				<S.Wrapper>
					{services.map(
						(
							{ title, content, cta_label, cta_link, image: { imgix_url } },
							index
						) => (
							<Item
								key={index}
								title={title}
								content={content}
								label={cta_label}
								link={cta_link}
								img={imgix_url}
							/>
						)
					)}
				</S.Wrapper>
			</S.Section>
		</>
	);
};

export default MobileService;
