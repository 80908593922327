import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Slider from 'react-slick';
import '@styles/slider.sass';

import { orderAndHideItems } from '@utils/filter';

import Testimony from './testimony';

import * as S from './styles';

const settings = {
	focusOnSelect: true,
	centerPadding: '16px',
	centerMode: true,
	speed: 500,
	arrows: false,
	dots: true,
	slidesToShow: 1,
	variableWidth: true,
};

const Testimonies = () => {
	const { allCosmicjsHomepage } = useStaticQuery(graphql`
		{
			allCosmicjsHomepage(filter: { slug: { eq: "testimonies" } }) {
				nodes {
					metadata {
						background {
							imgix_url
						}
						hat
						title
						testimonies {
							name
							order
							show
							since
							testimony
						}
					}
				}
			}
		}
	`);

	const {
		background: { imgix_url },
		hat,
		title,
		testimonies,
	} = allCosmicjsHomepage.nodes[0].metadata;

	const filteredTestimonies = useMemo(() => orderAndHideItems(testimonies), [
		testimonies,
	]);

	const filterLength = useMemo(() => filteredTestimonies.length, [
		filteredTestimonies.length,
	]);

	return (
		<>
			{filterLength > 6 ? (
				<S.SlideContainer background={imgix_url}>
					<S.Hat>{hat}</S.Hat>
					<S.Title>{title}</S.Title>

					<Slider {...settings}>
						{filteredTestimonies.map(({ name, since, testimony }, index) => (
							<Testimony
								key={index}
								name={name}
								since={since}
								testimony={testimony}
							/>
						))}
					</Slider>
				</S.SlideContainer>
			) : (
				<></>
			)}
		</>
	);
};

export default Testimonies;
