import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Testimony = ({ name, since, testimony, ...rest }) => {
	return (
		<>
			<div {...rest}>
				<S.Item>
					<S.ItemName>{name}</S.ItemName>
					<S.ItemSince>{since}</S.ItemSince>
					<S.ItemTestimony>&ldquo;{testimony}&ldquo;</S.ItemTestimony>
				</S.Item>
			</div>
		</>
	);
};

Testimony.propTypes = {
	name: PropTypes.string.isRequired,
	since: PropTypes.string.isRequired,
	testimony: PropTypes.string.isRequired,
};

export default Testimony;
