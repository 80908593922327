import styled from 'styled-components';

import { media, spacing, toRem } from '@styles/Theme';

import {
	Section as LSection,
	Wrapper as LWrapper,
} from '@components/Layout/styles';

import Link from '@components/Link';

export const Section = styled(LSection)`
	overflow: hidden;
	background-color: #fff;

	@media (${media.tablet}) {
		padding: ${spacing(20, 0)};
	}
`;

export const Wrapper = styled(LWrapper)``;

export const Item = styled.div`
	&:not(:first-child) {
		margin-top: ${spacing(7)};
	}

	@media (${media.mobileOnly}) {
		max-width: ${toRem(480)};
		margin-right: auto;
		margin-left: auto;
	}

	@media (${media.tablet}) {
		display: flex;
		align-items: center;

		&:first-child {
			> div:first-child {
				margin-right: ${spacing(5)};
			}
		}

		&:last-child {
			flex-direction: row-reverse;

			> div:first-child {
				margin-left: ${spacing(5)};
			}
		}
	}

	@media (${media.desktop}) {
		&:not(:first-child) {
			margin-top: ${spacing(20)};
		}

		&:first-child {
			> div:first-child {
				margin-right: ${spacing(30)};
			}
		}

		&:last-child {
			> div:first-child {
				margin-left: ${spacing(30)};
			}
		}
	}
`;

export const TextContainer = styled.div`
	text-align: center;

	@media (${media.mobileOnly}) {
		margin-top: ${spacing(5)};
	}

	@media (${media.tablet}) {
		text-align: left;
	}
`;

export const Content = styled.div`
	margin-bottom: ${spacing(3)};
`;

export const CTA = styled(Link)``;
