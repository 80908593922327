import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import * as S from './styles';

const Item = ({ mobileTitle, mobileDescription, link, img, ...rest }) => {
	return (
		<>
			<div {...rest}>
				<S.SlideItem>
					<S.OnMobile>
						<S.SlideContent>
							<S.SlideTitle>{mobileTitle}</S.SlideTitle>
							<S.SlideDescription>{mobileDescription}</S.SlideDescription>
							{link && <S.SlideButton to={link}>Saiba mais</S.SlideButton>}
						</S.SlideContent>
					</S.OnMobile>

					<S.OnDesktop>
						{link ? (
							<Link to={link}>
								<img src={img} alt={`${mobileTitle} - ${mobileDescription}`} />
							</Link>
						) : (
							<img src={img} alt={`${mobileTitle} - ${mobileDescription}`} />
						)}
					</S.OnDesktop>
				</S.SlideItem>
			</div>
		</>
	);
};

Item.propTypes = {
	mobileTitle: PropTypes.string.isRequired,
	mobileDescription: PropTypes.string.isRequired,
	link: PropTypes.string,
	img: PropTypes.string.isRequired,
};

Item.defaultProps = {
	link: undefined,
};

export default Item;
