import React from 'react';

import CTA from '@components/CTAS/Appointment';

import * as S from './styles';

const Appointments = () => {
	return (
		<S.Section>
			<CTA noSpacing />
		</S.Section>
	);
};

export default Appointments;
