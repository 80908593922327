import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Slider from 'react-slick';
import '@styles/slider.sass';

import { orderAndHideItems } from '@utils/filter';
import Item from './Item';

import * as S from './styles';

const settings = {
	adaptiveHeight: true,
	autoplay: true,
	autoplaySpeed: 5000,
	dots: true,
	draggable: false,
	infinite: true,
	nextArrow: <S.Next size={64} />,
	pauseOnHover: true,
	prevArrow: <S.Prev size={64} />,
	slidesToScroll: 1,
	slidesToShow: 1,
	speed: 500,
};

const MainBanner = () => {
	const { allCosmicjsHomepage } = useStaticQuery(graphql`
		{
			allCosmicjsHomepage(filter: { slug: { eq: "banners" } }) {
				nodes {
					metadata {
						banners {
							mobile_description
							mobile_title
							link
							show
							desktop_image {
								imgix_url
							}
							order
						}
					}
				}
			}
		}
	`);

	const { banners } = allCosmicjsHomepage.nodes[0].metadata;

	const bannerList = orderAndHideItems(banners);

	return (
		<>
			<S.SlideContainer>
				<Slider {...settings}>
					{bannerList.map(
						(
							{
								mobile_title,
								mobile_description,
								link,
								desktop_image: { imgix_url },
							},
							index
						) => (
							<Item
								key={index}
								mobileTitle={mobile_title}
								mobileDescription={mobile_description}
								link={link}
								img={imgix_url}
							/>
						)
					)}
				</Slider>
			</S.SlideContainer>
		</>
	);
};

export default MainBanner;
