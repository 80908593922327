import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '@components/seo';
import MainBanner from '@components/HomeComponents/MainBanner';
import Differentials from '@components/HomeComponents/Differentials';
import MobileService from '@components/HomeComponents/MobileService';
import Testimonies from '@components/HomeComponents/Testimonies';
import Appointment from '@components/HomeComponents/Appointment';

const IndexPage = () => {
	const { meta } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-home" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}
		}
	`);

	const {
		metadata: { title, description, cover },
	} = meta.nodes[0];

	return (
		<>
			<SEO
				title={title || 'Home'}
				description={description}
				cover={cover.imgix_url}
			/>
			<MainBanner />
			<Differentials />
			<MobileService />
			<Testimonies />
			<Appointment />
		</>
	);
};

export default IndexPage;
