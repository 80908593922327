import styled, { css } from 'styled-components';

import { colors, fontSize, media, toRem, spacing, zIndex } from '@styles/Theme';
import { H3, H4, H5, P } from '@styles/Typography';

import { Section as LSection } from '@components/Layout/styles';

const slideBackground = bg => css`
	position: relative;
	background-image: url(${bg});
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.6);
		content: '';
	}
`;

const hideText = css`
	font-size: 0;
	text-indent: 100%;
`;

export const Hat = styled(H4)`
	position: relative;
	z-index: ${zIndex.floated};
	color: ${colors.primary};
`;

export const Title = styled(H3)`
	position: relative;
	z-index: ${zIndex.floated};
	color: white;
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: ${toRem(280)};
	margin: ${spacing(0, 2)};
	padding: ${spacing(6, 3)};
	text-align: center;
	background-color: #fff;
	border-radius: ${spacing(2)};
	box-shadow: 0px 0px 40px #e0e0e029;
	cursor: pointer;
	transition: transform 0.2s linear;

	&:focus {
		outline: none;
	}
`;

export const SlideContainer = styled(LSection)`
	text-align: center;

	${({ background }) =>
		background
			? slideBackground(background)
			: css`
					background-color: #fafafa;
					background-image: linear-gradient(#fafafa, #f9f9f9);
			  `};

	.slick-slide {
		width: ${toRem(320)};

		@media (${media.mobileOnly}) {
			width: ${toRem(260)};
		}
	}

	.slick-center {
		${Item} {
			transform: scale(1.1);
		}
	}

	.slick-track {
		padding: ${spacing(4, 0)};
	}

	.slick-dots {
		position: absolute;
		top: calc(100% + ${spacing(2)});
		display: flex !important;
		justify-content: center;
		width: 100%;

		li {
			button {
				${hideText}
				position: relative;
				display: block;
				width: ${spacing(2)};
				height: ${spacing(2)};
				background-color: transparent;
				border: none;
				outline: none;

				&::after {
					position: absolute;
					top: calc(50% - ${spacing(0.5)});
					left: calc(50% - ${spacing(0.5)});
					display: block;
					width: ${spacing(1)};
					height: ${spacing(1)};
					background-color: ${colors.lightGrey};
					border-radius: 100%;
					content: '';
				}
			}

			&.slick-active {
				button::after {
					background-color: ${colors.primary};
				}
			}
		}
	}

	@media (${media.mobileOnly}) {
		padding-bottom: ${spacing(8)};
	}
`;

export const ItemName = styled(H5)`
	color: ${colors.primary};
`;

export const ItemSince = styled.span`
	display: block;
	color: ${colors.lightGrey};
	font-size: ${fontSize.small};
`;

export const ItemTestimony = styled(P)``;
