import styled, { css } from 'styled-components';

import { colors, media, spacing, toRem, zIndex } from '@styles/Theme';
import { H1 } from '@styles/Typography';

import { ChevronRightIcon, ChevronLeftIcon } from '@components/Icons';

import bgLeft from '@images/bg-banner-left.svg';
import bgRight from '@images/bg-banner-right.svg';

import Button from '@components/Button';

const hideText = css`
	font-size: 0;
	text-indent: 100%;
`;

const buttonStyle = side => css`
	${side}: ${spacing(3)};
	color: white;
	cursor: pointer;
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

	@media (${media.mobileOnly}) {
		${side}: ${spacing(0)};
	}
`;

const bg = ({ height, position, image }) => css`
	position: absolute;
	width: ${toRem(200)};
	height: ${height};
	background-image: url(${image});
	background-repeat: no-repeat;
	background-position: ${position};
	background-size: contain;
	content: '';
`;

export const SlideContainer = styled.div`
	position: relative;

	.slick-arrow {
		position: absolute;
		top: 50%;
		z-index: ${zIndex.floated};
		padding: 0;
		background: transparent;
		border: none;
		outline: none;
		transform: translateY(-50%);

		${hideText}
	}

	.slick-dots {
		position: absolute;
		top: calc(100% + ${spacing(2)});
		display: flex !important;
		justify-content: center;
		width: 100%;

		li {
			button {
				${hideText}
				position: relative;
				display: block;
				width: ${spacing(2)};
				height: ${spacing(2)};
				background-color: transparent;
				border: none;
				outline: none;

				&::after {
					position: absolute;
					top: calc(50% - ${spacing(0.5)});
					left: calc(50% - ${spacing(0.5)});
					display: block;
					width: ${spacing(1)};
					height: ${spacing(1)};
					background-color: ${colors.lightGrey};
					border-radius: 100%;
					content: '';
				}
			}

			&.slick-active {
				button::after {
					background-color: ${colors.primary};
				}
			}
		}
	}

	img {
		width: 100%;
	}

	@media (${media.mobileAndTablet}) {
		&::before {
			bottom: 0;
			left: 0;

			${bg({ height: toRem(104), position: 'bottom left', image: bgLeft })}
		}

		&::after {
			top: ${toRem(-70)};
			right: 0;

			${bg({ height: toRem(200), position: 'top right', image: bgRight })}
		}

		@media (${media.tablet}) {
			&::before {
				height: ${toRem(320)};
			}

			&::after {
				height: ${toRem(320)};
			}
		}
	}

	@media (${media.desktop}) {
		padding: 0;
		background-color: #fafafa;
	}
`;

export const SlideItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const SlideContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: ${toRem(360)};
	min-height: ${spacing(40)};
	padding: ${spacing(3)};
`;

export const SlideTitle = styled(H1)``;

export const SlideDescription = styled.p``;

export const SlideButton = styled(Button)`
	margin: ${spacing(3, 'auto', 0)};
`;

export const Prev = styled(ChevronLeftIcon)`
	${buttonStyle('left')}
`;

export const Next = styled(ChevronRightIcon)`
	${buttonStyle('right')}
`;

export const OnMobile = styled.div`
	@media (${media.desktop}) {
		display: none;
	}
`;
export const OnDesktop = styled.div`
	width: 100%;

	img {
		display: block;
	}

	@media (${media.mobileAndTablet}) {
		display: none;
	}
`;
